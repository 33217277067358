/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect,useRef } from 'react'
import {useHistory} from 'react-router-dom'
import Swal from 'sweetalert2'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getRequestOptions } from '../components/GetToken'
import { postRequestOptions } from '../helpers/Fetchwrapper'


function ShiftCreate (props) {
  let history = useHistory();
  const [id, setId] = useState('');
  const [shiftName, setShiftName] = useState('');
  const [shiftStartTime, setShiftStartTime] = useState('');
  const [shiftEndTime, setShiftEndTime] = useState('');
  const [shiftAllowTime, setShiftAllowtTime] = useState('');
  const [status, setStatus] = useState('active');
  
  const myRef = useRef();
  
  useEffect(()=>{
    //setShiftName(props.name);
    if(id !== props.id || shiftName !== props.name){
    setId(props.id);
    getShiftDetails(props.id);
    window.scrollTo(0, 0);
    myRef.current.focus();
    }
  },[ props.id, props.name]);


  const getShiftDetails = (shiftId) => {
    fetch(process.env.REACT_APP_API_URL + "shift_details/" + shiftId,
    getRequestOptions())
    .then((resp) => {
        return resp.json()
    })
    .then((resp) => {
        setShiftName(resp.shift_name); 
        setShiftStartTime(resp.start_time);
        setShiftEndTime(resp.end_time);
        setShiftAllowtTime(resp.allow_time);
        setStatus(resp.publication_status);
    })
    .catch((error) => {
        console.log(error, "catch the hoop")
    });
  }
 

  const statusType = [{label:'active',value:'active'},{label:'inactive',value:'inactive'}]

  const onShiftStartTimeChange = (e) => {
    setShiftStartTime(e.target.value)
  }

  const handleSubmit = (evt) => {
    evt.preventDefault()
    if (id !== '') {
      var text = 'You want to update this Shift!'
    } else {
      text = 'You want to add this Shift!'
    }
    Swal.fire({
      title: 'Are you sure?',
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        const jwt = () => {
          return JSON.parse(localStorage.getItem('MyToken'))
        }
        const formData = {
          id: id,
          shiftName: shiftName,
          shiftStartTime: shiftStartTime,
          shiftEndTime: shiftEndTime,
          shiftAllowTime: shiftAllowTime,
          status: status 
        }
        
        const requestOptions = postRequestOptions(formData);

        if (id !== '') {
          var apiEnd = 'shift_update'
        } else {
          apiEnd = 'shift_create'
        }

        fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
          .then((resp) => {
            return resp.json()
          })
          .then((resp) => {
            console.log(resp)

            if (resp.success === true) {
              if (id !== '') {
                var successMsg = [`Shift '${resp.data.name}' updated`]
              } else {
                successMsg = [`Shift '${resp.data.name}' added`]
              }

              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: successMsg,
              })
              setId('');
              setShiftName('');
              setShiftStartTime('');
              setShiftEndTime('');
              setShiftAllowtTime('');
              setStatus('');
              props.onCreate({id: resp.data.id, name: resp.data.name})
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                html: resp.errorMessage,
              })
            }
          })
          .catch((error) => {
            console.log(error, 'catch the hoop')
          })
      }
    })
  }

  return (
    <div className='card-body'>
      <div className='ml-12' >
        <form onSubmit={handleSubmit}>
          <div className='form-group row'>
            <label
              htmlFor='shiftName'
              className='col-lg-3 col-form-label'
              style={{textAlign: 'right'}}
            >
              Shift Name<span className='required text-danger'></span>
            </label>
            <div className='col-lg-5'>
              <input
                type='text'
                name='shiftName'
                id='shiftName'
                ref={myRef}
                value={shiftName}
                onChange={(e) => setShiftName(e.target.value)}
                className='form-control form-control-sm'
              />
            </div>
          </div>

          <div className='form-group row'>
            <label
              htmlFor='shiftStartTime'
              className='col-lg-3 col-form-label'
              style={{textAlign: 'right'}}
            >
              Start Time<span className='required text-danger'></span>
            </label>
            <div className='col-lg-5'>
              <input
                type='time'
                name='shiftStartTime'
                id='shiftStartTime'
                value={shiftStartTime}
                onChange={onShiftStartTimeChange}
                className='form-control form-control-sm'
              />
            </div>
          </div>

          <div className='form-group row'>
            <label
              htmlFor='shiftEndTime'
              className='col-lg-3 col-form-label'
              style={{textAlign: 'right'}}
            >
              End Time<span className='required text-danger'></span>
            </label>
            <div className='col-lg-5'>
              <input
                type='time'
                name='shiftEndTime'
                id='shiftEndTime'
                value={shiftEndTime}
                onChange={(e)=>setShiftEndTime(e.target.value)}
                className='form-control form-control-sm'
              />
            </div>
          </div>

          <div className='form-group row'>
            <label
              htmlFor='shiftAllowTime'
              className='col-lg-3 col-form-label'
              style={{textAlign: 'right'}}
            >
              Allow Time<span className='required text-danger'></span>
            </label>
            <div className='col-lg-5'>
              <input
                type='time'
                name='shiftAllowTime'
                id='shiftAllowTime'
                value={shiftAllowTime}
                onChange={(e)=>setShiftAllowtTime(e.target.value)}
                className='form-control form-control-sm'
              />
            </div>
          </div>

          <div className="form-group row">
                <label htmlFor="status" className="col-lg-3 col-form-label"  style={{ "textAlign": "right" }} >Status<span className="required text-danger"></span></label>
                <div className="col-lg-5">
                    <select className="form-select form-select-sm" id="status" name="status" value={status} onChange={(e)=> setStatus(e.target.value)} >
                        {statusType.map(function (item, id) {
                            return <option key={id} value={item.value}>{item.label}</option>
                        })
                        }
                    </select>
                </div>
        </div>


          <div className='row'>
            <div className='col-4'></div>
            <div className='col-6'>
              <input
                type='submit'
                className='btn btn-sm btn-success mt-2'
                value={id !== '' ? 'Update' : 'Save'}
              />
              
            </div>
          </div>
          {/* <pre>
            {JSON.stringify(shiftName, null, 2)}
            {JSON.stringify(status, null, 2)}
          </pre> */}
        </form>
      </div>
      {/* <ToastContainer /> */}
    </div>
  )
}

export default ShiftCreate
