import React, {useState, useEffect, useRef} from 'react'
import {useHistory,Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {KTSVG} from '../../_metronic/helpers'
import {getRequestOptions} from '../components/GetToken'
import {Tab, Tabs} from 'react-bootstrap-v5'

const BomDetails = (props) => {

  const [details,setDetails] = useState({bomoperations:[]});

  useEffect(()=>{
    getBomDetails(props.match.params.id);
  },[])

  const getBomDetails = (bomId) => {

    fetch(process.env.REACT_APP_API_URL + "bom_details/" + bomId,
         getRequestOptions())
        .then((resp) => {
            return resp.json()
        })
        .then((resp) => {
            setDetails(resp);
        })
        .catch((error) => {
            console.log(error, "catch the hoop")
        });
  }

  const tableBody = () => {
    const tbodies = details.bomoperations.map((row, index) => {
    
      const operationItemsValues = Object.values(row.bomcomponents)
    
      let operationItemRows = null;

      if(operationItemsValues.length === 0){
        operationItemRows =  <tr key={`${index}ni`}>
          <td>{index + 1}</td>
          <td> {row.operation.operationName}</td>
          <td>{row.work_center!==null?row.work_center.workCenterName:''}</td>
          <td>{row.duration}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      }

     else{
       operationItemRows = operationItemsValues.map((bomcomponent, i) => {
       
        const slNo = i === 0 ? <td rowSpan={operationItemsValues.length}>{index + 1}</td> : null
        const operationCell =
          i === 0 ? (
            <td  rowSpan={operationItemsValues.length}>
           {row.operation.operationName}
            </td>
          ) : null

        const workCenter = i === 0 ? <td rowSpan={operationItemsValues.length}>{row.work_center!==null?row.work_center.workCenterName:''}</td> : null

        const duration = i === 0 ? <td rowSpan={operationItemsValues.length}>{row.duration}</td> : null


        return (
          <tr key={i}>
              {slNo}
              {operationCell}
              {workCenter}
              {duration}
              <td>{bomcomponent.item.itemName}</td>
              <td>{bomcomponent.item.itemCode}</td>
              <td>{bomcomponent.item.unit}</td>
              <td>{bomcomponent.quantity}</td>
           
          </tr>
        )
      })}
      return operationItemRows
       //return <>{operationItemRows}</>
    })
   

    return tbodies
  }

  return (
    <div className='card card-custom'>
      <div className='card-header'>
        <div className='card-title'>
          <KTSVG
            path='/media/icons/duotune/text/txt001.svg'
            className='svg-icon-1 svg-icon-primary'
          />
          <span className='text fw-bolder' style={{marginLeft: '10px'}}>
            BOM Details
          </span>
        </div>

        <div class="card-toolbar mt-5">
        <Link to={`/bom-edit/${props.match.params.id}`}>
            <button class="btn btn-primary btn-sm ml-2">Edit</button>
        </Link>
        </div>
      </div>
      <div className='card-body'>
        <div className='ml-12'>
          <div className='row'>
            <div className='col-6'>
              <table className='table table-bordered table-hover'>
                <tbody>
                  <tr>
                    <th>Product</th>
                    <td>{details.product!=undefined && details.product.itemName}</td>
                  </tr>
                  <tr>
                    <th>Product Code</th>
                    <td>{details.product!=undefined && details.product.itemCode}</td>
                  </tr>
                  <tr>
                    <th>Unit</th>
                    <td>{details.product!=undefined && details.product.unit}</td>
                  </tr>
                  <tr>
                    <th>Quantity</th>
                    <td>{details.quantity}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className='col-6'>
              <table className='table table-bordered table-hover'>
                <tbody>
                  <tr>
                    <th>Bom Type</th>
                    <td>{details.bomType}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className='col-12'>
              <Tabs defaultActiveKey='bom-components'>
                <Tab eventKey='bom-components' title='Components'>
                  <div className='table-responsive'>
                    <table
                      className='table table-bordered table-hover'
                      style={{minWidth: 'max-content'}}
                    >
                      <thead>
                        <tr>
                          <th>SL</th>
                          <th>Route Stage</th>
                          <th>Work Center</th>
                          <th>Duration</th>
                          <th>Item</th>
                          <th>Code</th>
                          <th>Unit</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                          { tableBody() }
                      </tbody>
                    </table>
                  </div>
                </Tab>
               
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BomDetails
