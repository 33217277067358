import React, {useState, useContext, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {KTSVG} from '../../_metronic/helpers'
import Swal from 'sweetalert2'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'
import {getRequestOptions} from '../components/GetToken'
import {AppContext} from '../context/AppContext'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const customStylesSelect = {
  control: (provided) => ({
    ...provided,
    height: 'calc(1.5em + 1.3rem + 2px)',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 'calc(1.5em + 1.3rem + 2px)',
  }),
}

const CompanySelection = () => {
  const [pic, setPic] = useState('')
  const [fullname, setFullname] = useState('')
  const [userInfo, setUserInfo] = useState('')
  const [businessUnit, setBusinessUnit] = useState('')
  const [allBusinessUnits, setAllBusinessUnits] = useState([])
  const [company, setCompany] = useState('')
  const [companyList, setCompanyList] = useState([])

  const {bu, changeBu} = useContext(AppContext)

  useEffect(() => {
    getUserInfo()
    fetchAllBu()
  }, [bu])

  const history = useHistory()

  const getUserInfo = () => {
    fetch(process.env.REACT_APP_API_URL + 'users/get_user_info', getRequestOptions())
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        setFullname(resp.data.fullName)
        setPic(resp.data.fileName)
        setUserInfo(resp.data)
      })
      .catch((error) => {
        console.log(error, 'catch the hoop')
      })
  }

  const getAllCompanies = () => {
    fetch(process.env.REACT_APP_API_URL + 'api/get_companies', getRequestOptions())
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        setCompanyList(resp.data)
      })
      .catch((error) => {
        console.log(error, 'catch the hoop')
      })
  }

  const fetchAllBu = async () => {
    try {
      const requestOptions = getRequestOptions()
      const response = await fetch(
        process.env.REACT_APP_API_URL + 'get_user_business_units',
        requestOptions
      )
      const resp = await response.json()
      const tempResp = resp.data.map(function (element) {
        return {...element, label: element.projectName, value: element.projectId}
      })
      setAllBusinessUnits(tempResp)
      //console.log(bu);
      setBusinessUnit(tempResp.filter((x) => x.value === bu)[0])
    } catch (error) {
      console.log(error)
    }
  }

  const handleBusinessUnitChange = (businessUnit) => {
    setBusinessUnit(businessUnit)
  }

  const handleSubmit = (evt) => {
    const manuAppInfo = {
      company: '',
      businessUnit: businessUnit.value,
      userDetails: userInfo,
    }
    changeBu(manuAppInfo)
    //console.log(manuAppInfo);
    //localStorage.setItem('manuAppInfo', JSON.stringify(manuAppInfo))
    history.push('/dashboard')
  }

  return (
    <div className='col-md-4 offset-md-4 mt-10'>
      <div className='card card-custom'>
        <div className='card-title'>
          <div className='logo text-center'>
            <img src='assets/images/logo.png' alt='' />
          </div>

          <div className='caption'>
            <div className='text-center'>
             { pic!=="" &&
              <><img
                alt=''
                className='img-circle'
                src={process.env.REACT_APP_USER_ASSET + pic}
                height='96px'
                width='96px'
              /></>
            }
            </div>
            { pic==="" && <> 
            <div style={{ width:'96px',height:'96px',margin: 'auto' }}>
						<Skeleton count={4} ></Skeleton>
					</div>
          </>}
            <div className='text-center'>
              <h4>
                <div className='caption-subject bold'>{fullname}</div>
              </h4>
            </div>
          </div>
        </div>
        <div className='card-body'>
          <div className='form-group row'>
            <label htmlFor='example-text-input' className='col-4 col-form-label'>
              Business Unit
            </label>
            <div className='col-8'>
              <Select
                value={businessUnit}
                onChange={handleBusinessUnitChange}
                options={allBusinessUnits}
                styles={customStylesSelect}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <button
                onClick={handleSubmit}
                style={{marginTop: '10px'}}
                type='button'
                className='form-control btn btn-primary'
              >
                {' '}
                Go{' '}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanySelection
