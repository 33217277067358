import React, {useState} from 'react';
import {KTSVG} from '../../_metronic/helpers';
import ExpenseCreate from '../components/ExpenseCreate';
import ExpenseDt from '../components/ExpenseDt';

function Expense() {

    const [id, setId] = useState("");
    const [newExpenseType, setNewExpenseType] = useState([]);
    const [expenseType, setExpenseType] = useState("");

    const onCreate = (newExpenseType) => {
        setNewExpenseType(newExpenseType);
    }

    const onAction = (id,expenseType) => {
        setId(id);
        setExpenseType(expenseType);
    }

    return (
        <>
        <div className="card card-custom">
            <div className="card-header">
                <div className="card-title">
                        <KTSVG
                        path='/media/icons/duotune/finance/fin008.svg'
                        className='svg-icon-1 svg-icon-primary'
                        />
                    <span className="text fw-bolder" style={{marginLeft:"10px"}}>
                        ADD EXPENSE
                    </span>
                </div>
            </div>
            <div className="card-body">
                <ExpenseCreate onCreate={onCreate} id={id} expenseType={expenseType}/>
            </div>
        </div>
        <div className="card card-custom mt-5">
            <div className="card-header">
                <div className="card-title">
                        <KTSVG
                        path='/media/icons/duotune/text/txt001.svg'
                        className='svg-icon-1 svg-icon-primary'
                        />
                    <span className="text fw-bolder" style={{marginLeft:"10px"}}>
                        Expense List
                    </span>
                </div>
            </div>
            <div className="card-body">
                <ExpenseDt addNewExpenseType={newExpenseType} onAction={onAction}/>
            </div>
        </div>
        </>
    )
}

export default Expense