import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import Swal from 'sweetalert2'
import { KTSVG } from '../../_metronic/helpers'
import DataTable from '../components/DataTable'
import { postRequestOptions } from '../helpers/Fetchwrapper'

function WorkCenter() {
  let [workCenterInfo, setWorkCenterInfo] = useState({
    workCenterName: '',
    workCenterCode: '',
    workingHours: '',
    costPerHour: '',
  })
  const [newData, setNewData] = useState('')
  const [workCenterList, setWorkCenterList] = useState([]);

  const url = process.env.REACT_APP_API_URL + 'work_center/list'

  const handleChange = (e) => {
    setWorkCenterInfo({...workCenterInfo, [e.target.name]: e.target.value})
  }

  const handleSelectChange = (e) => {
    setWorkCenterInfo({...workCenterInfo, ['workingHours']: e.value})
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault()
    
    if (
      workCenterInfo.workCenterName === '' ||
      workCenterInfo.workCenterCode === '' ||
      workCenterInfo.workingHours === ''
    ) {
      alert('Please fill all required fields')
    } else {
      const requestOptions = postRequestOptions(workCenterInfo);
      const response = await fetch(`${process.env.REACT_APP_API_URL}work_center/create`, requestOptions)

      const data = await response.json()

      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'QC Data Saved Successfully',
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          html: data.errorMessage,
        })
      }

      console.log(data)
    }
  }

  const fetchWorkCenter = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}work_center/list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('MyToken')).token,
      },
    })

    const data = await response.json()

    console.log(data)
  }

  const workingHoursOptions = [
    {value: 8, label: 'Standard 8 Hours/Day'},
    {value: 16, label: 'Standard 16 Hours/Day'},
    {value: 40, label: 'Standard 40 Hours/Week'},
    {value: 48, label: 'Standard 48 Hours/Week'},
  ]

  const columns = [
    {value: 'id', title: 'Id', style: {textAlign: 'center'}},
    {value: 'workCenterName', title: 'Work Center Name', style: {textAlign: 'left'}},
    {value: 'workCenterCode', title: 'Work Center Code', style: {textAlign: 'left'}},
    {value: 'workingHours', title: 'Working Hours', style: {textAlign: 'center'}},
    {value: 'costPerHour', title: 'Cost Per Hour', style: {textAlign: 'right'}},
    {value: 'description', title: 'Description', style: {textAlign: 'left'}},
    {
      value: 'action',
      title: 'Action',
      actions: [{link: 'edit', calAction: true}],
      style: {textAlign: 'center'},
    },
  ]

  const filters = {
    id: {
      filterType: 'like',
      filterValue: '',
    },
    workCenterName: {
      filterType: 'like',
      filterValue: '',
    },
    workCenterCode: {
      filterType: 'like',
      filterValue: '',
    },
    workingHours: {
      filterType: 'like',
      filterValue: '',
    },
    costPerHour: {
      filterType: 'like',
      filterValue: '',
    },
    description: {
      filterType: 'like',
      filterValue: '',
    },
  }

  const onAction = (data) => {
    console.log(data)
  }

  useEffect(() => {
    // fetchWorkCenter()
  }, [])

  return (
    <>
      <div className='card card-custom'>
        <div className='card-header'>
          <div className='card-title'>
            <KTSVG
              path='/media/icons/duotune/ecommerce/ecm006.svg'
              className='svg-icon-1 svg-icon-primary'
            />
            <span className='text fw-bolder' style={{marginLeft: '10px'}}>
              Crete Work Center
            </span>
          </div>
        </div>

        <div className='ml-12'>
          <form onSubmit={handleSubmit}>
            <div className='card-body'>
              <div className='form-group row'>
                <label
                  htmlFor='workCenter'
                  className='col-lg-3 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Work Center <span className='required text-danger'></span>
                </label>
                <div className='col-lg-6'>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    id='workCenterName'
                    name='workCenterName'
                    placeholder='Work Center'
                    value={workCenterInfo.workCenterName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label
                  htmlFor='code'
                  className='col-lg-3 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Code <span className='required text-danger'></span>
                </label>
                <div className='col-lg-6'>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    id='workCenterCode'
                    name='workCenterCode'
                    placeholder='Code'
                    value={workCenterInfo.workCenterCode}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className='form-group row'>
                <label
                  htmlFor='code'
                  className='col-lg-3 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Working Hours <span className='required text-danger'></span>
                </label>
                <div className='col-lg-6'>
                  <Select options={workingHoursOptions} onChange={handleSelectChange} />
                </div>
              </div>
              <div className='form-group row'>
                <label
                  htmlFor='costPerHour'
                  className='col-lg-3 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Cost Per Hour <span className='required text-danger'></span>
                </label>
                <div className='col-lg-6'>
                  <input
                    type='text'
                    className='form-control form-control-sm'
                    id='costPerHour'
                    name='costPerHour'
                    placeholder='Cost Per Hour'
                    value={workCenterInfo.costPerHour}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='form-group row'>
                <label
                  htmlFor='code'
                  className='col-lg-3 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Description
                </label>
                <div className='col-lg-6'>
                  <textarea
                    className='form-control form-control-sm'
                    id='description'
                    name='description'
                    placeholder='Description'
                    value={workCenterInfo.description}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className='form-group row'>
                <div className='col-3'></div>
                <div className='col-6'>
                  <input type='submit' className='btn btn-sm btn-success mt-2' value='Save' />
                </div>
              </div>
              <br />

              <div className='form-group row'>
                <DataTable
                  url={url}
                  columns={columns}
                  filters={filters}
                  newData={newData}
                  onAction={onAction}
                >
                  <span className='svg-icon svg-icon-md svg-icon-primary'>
                    <KTSVG
                      path='/media/icons/duotune/art/art005.svg'
                      className='svg-icon-1 svg-icon-3'
                    />
                  </span>
                </DataTable>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default WorkCenter
