import React, { useEffect, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { KTSVG } from '../../_metronic/helpers'
import { getRequestOptions } from '../components/GetToken'

const WipDetails = (props) => {
  const [details, setDetails] = useState('')

  useEffect(() => {
    getQcDetails(props.match.params.id)
  }, [])

  const getQcDetails = (qcId) => {
    fetch(`${process.env.REACT_APP_API_URL}qc/qc_details/${qcId}`, getRequestOptions())
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        setDetails(resp)
      })
      .catch((error) => {
        console.log(error, 'catch the hoop')
      })
  }



  return (
    <div className='card card-custom'>
      <div className='card-header'>
        <div className='card-title'>
          <KTSVG
            path='/media/icons/duotune/text/txt001.svg'
            className='svg-icon-1 svg-icon-primary'
          />
          <span className='text fw-bolder' style={{marginLeft: '10px'}}>
            QC Details
          </span>
        </div>
      </div>
      <div className='card-body'>
        <div className='ml-12'>
          <div className='row'>
            <div className='col-6'>
              <table className='table table-bordered table-hover'>
                <tbody>
                  <tr>
                    <th>QC Date</th>
                    <td>{details.qcDate != undefined && details.qcDate}</td>
                  </tr>
                  <tr>
                    <th>Shift</th>
                    <td>{details.shift != undefined && details.shift.shiftName}</td>
                  </tr>
                  <tr>
                    <th>Warehouse</th>
                    <td>{details.warehouse != undefined && details.warehouse.warehouseName}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className='col-12'>
              <div className='table-responsive'>
                <table
                  className='table table-bordered table-hover'
                  style={{minWidth: 'max-content'}}
                >
                  <thead>
                    <tr>
                      <th>SL</th>
                      <th style={{width: '25%'}}>Product</th>
                      <th>Unit</th>
                      <th>QC Passed Qty</th>
                      <th>Damage Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {  details.qcitems !== undefined && details.qcitems.map((item,index) => 
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.product.itemName}</td>
                      <td>{item.product.unit}</td>
                      <td>{item.qcPassedQty}</td>
                      <td>{item.damageQuantity}</td>
                    </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WipDetails
