/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'

const HeaderUserMenu: FC = () => {

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-5'>
        <a target='_blank' href={process.env.REACT_APP_LOGIN+'#/profile/basic'} className='menu-link px-5' rel="noreferrer">
          My Profile
        </a>
      </div>

      <div className='menu-item px-5'>
        <Link to="/company-selection" className='menu-link px-5'>
          Change Company
        </Link>
      </div>
      <div className='menu-item px-5'>
        <Link to="/logout" className='menu-link px-5'>
          Sign Out
        </Link>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
