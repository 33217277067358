import React, {useState, useEffect, useRef} from 'react'
import Swal from 'sweetalert2'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {KTSVG} from '../../_metronic/helpers'
import {getRequestOptions} from '../components/GetToken'


const WipDetails = (props) => {

  const [details,setDetails] = useState('');

  useEffect(()=>{
    
    getWipDetails(props.match.params.id);
  },[])

  const getWipDetails = (wipId) => {
      
    fetch(process.env.REACT_APP_API_URL + "wip_details/" + wipId,
         getRequestOptions())
        .then((resp) => {
            return resp.json()
        })
        .then((resp) => {
            setDetails(resp);
        })
        .catch((error) => {
            console.log(error, "catch the hoop")
        });
  }

  const tableBody = () => {
    const tbodies =  details.wipitems !=undefined && details.wipitems.map((item, index) => {
      const componentValues = Object.values(item.components).length > 0 ? Object.values(item.components) : [{item:{itemName:"",unit:""},bomQty:"",actualQty:""}];
      const componentsRows = componentValues.map((bomComponent, i) => {
       
        const slNo = i === 0 ? <td rowSpan={componentValues.length}>{index + 1}</td> : null
        const product =
          i === 0 ? (
            <td  rowSpan={componentValues.length}>
              {item.product.itemName}
            </td>
          ) : null
        const unit = i === 0 ? <td rowSpan={componentValues.length}>{item.product.unit}</td> : null

        const quantity =
          i === 0 ? (
            <td rowSpan={componentValues.length}>
              {item.quantity}
            </td>
          ) : null

      

        return (
          <tr key={i}>
            {slNo}
            {product}
            {unit}
            {quantity}
            <td>{bomComponent.item.itemName}</td>
            <td>{bomComponent.item.unit}</td>
            <td>{bomComponent.bomQty}</td>
            <td>{bomComponent.actualQty}</td>
          </tr>
        )
      })
      return componentsRows
    })

    return tbodies
  }

  return (
    <div className='card card-custom'>
      <div className='card-header'>
        <div className='card-title'>
          <KTSVG
            path='/media/icons/duotune/text/txt001.svg'
            className='svg-icon-1 svg-icon-primary'
          />
          <span className='text fw-bolder' style={{marginLeft: '10px'}}>
          Work-in-progress Details
          </span>
        </div>
      </div>
      <div className='card-body'>
        <div className='ml-12'>
          <div className='row'>
            <div className='col-6'>
              <table className='table table-bordered table-hover'>
                <tbody>
                  <tr>
                    <th>WIP Date</th>
                    <td>{details.wipDate!=undefined && details.wipDate}</td>
                  </tr>
                  <tr>
                    <th>Shift</th>
                    <td>{details.shift!=undefined && details.shift.shiftName}</td>
                  </tr>
                  <tr>
                    <th>Warehouse</th>
                    <td>{details.warehouse!=undefined && details.warehouse.warehouseName}</td>
                  </tr>
                </tbody>
              </table>
            </div>


            <div className='col-12'>
        
                  <div className='table-responsive'>
                    <table
                      className='table table-bordered table-hover'
                      style={{minWidth: 'max-content'}}
                    >
                      <thead>
                        <tr>
                        <th>SL</th>
                        <th style={{width: '25%'}}>Product</th>
                        <th>Unit</th>
                        <th>Quantity</th>
                        <th>Row Materials</th>
                        <th>Unit</th>
                        <th>BOM Quantity</th>
                        <th>Actual Quantity </th>
                        </tr>
                      </thead>
                      <tbody>
                         {tableBody()}
                      </tbody>
                    </table>
                  </div>
                
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WipDetails
