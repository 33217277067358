const getCookie = (name) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
}

function getRequestOptions() {
	var jwt = { token: getCookie('MyToken') };
	let requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token }
	};
	return requestOptions;
}
export { getRequestOptions };

