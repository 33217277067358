
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FallbackView } from '../../_metronic/partials';
import BomCreate from '../pages/BomCreate';
import BomDetails from '../pages/BomDetails';
import BomList from '../pages/BomList';
import BomUpdate  from '../pages/BomUpdate';
import CompanySelection from '../pages/CompanySelection';
import DailyProductionReport from '../pages/DailyProductionReport';
import Dashboard from '../pages/Dashboard';
import Expense from '../pages/Expense';
import Operations from '../pages/Operations';
import PageList from '../pages/PageList';
import PageManagement from '../pages/PageManagement';
import ProductionItemMovementReport from '../pages/ProductionItemMovementReport';
import QcCutting from '../pages/QcCutting';
import QcHardeningTempering from '../pages/QcHardeningTempering';
import QcList from '../pages/QcList';
import QcDetails from '../pages/QcDetails';
import QcPainting from '../pages/QcPainting';
import QcShotPeening from '../pages/QcShotPeening';
import RoleManagement from '../pages/RoleManagement';
import Shift from '../pages/Shift';
import UserRolePermission from '../pages/UserRolePermission';
import WipCutting from '../pages/WipCutting';
import WipDetails from '../pages/WipDetails';
import WipHardeningTempering from '../pages/WipHardeningTempering';
import WipList from '../pages/WipList';
import WipPainting from '../pages/WipPainting';
import WipShotPeening from '../pages/WipShotPeening';
import WorkCenter from '../pages/WorkCenter';


export function PrivateRoutes() {

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={Dashboard} />
        <Route path='/setup-expense' component={Expense} />
        <Route path='/setup-work-center-create' component={WorkCenter} />
        <Route path='/setup-shift' component={Shift} />
        <Route path='/setup-operations' component={Operations} />
        <Route path='/bom-create' component={BomCreate} />
        <Route path='/bom-list' component={BomList} />
        <Route path='/bom-details/:id' component={BomDetails} />
        <Route path='/bom-edit/:id' component={BomUpdate} />
        <Route path='/wip-cutting' component={WipCutting} />
        <Route path='/wip-hardening-tempering' component={WipHardeningTempering} />
        <Route path='/wip-shot-peening' component={WipShotPeening} />
        <Route path='/wip-painting' component={WipPainting} />
        <Route path='/wip-list' component={WipList} />
        <Route path='/wip-details/:id' component={WipDetails} />
        <Route path='/qc-cutting' component={QcCutting} />
        <Route path='/qc-hardening-tempering' component={QcHardeningTempering} />
        <Route path='/qc-shot-peening' component={QcShotPeening} />
        <Route path='/qc-painting' component={QcPainting} />
        <Route path='/qc-list' component={QcList} />
        <Route path='/qc-details/:id' component={QcDetails} />
        <Route path='/company-selection' component={CompanySelection} />
        <Route path='/accesscontrol-rolemanagement' component={RoleManagement} />
        <Route path='/accesscontrol-userrolepermission' component={UserRolePermission} />
        <Route path='/accesscontrol-page-list' component={PageList} />
        <Route path='/accesscontrol-pagemanagement' component={PageManagement} />
        <Route path='/report_daily_production' component={DailyProductionReport} />
        <Route path='/report_production_item_movement' component={ProductionItemMovementReport} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/company-selection' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
